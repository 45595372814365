.a-event {
	margin: 3rem auto 0 auto;
	//padding-bottom: 5rem;
	display: block;
	width: 100%;

	@media (min-width: $breakpoint--md) {
	width: 100%;
}
	&__bubble {
		$bubble-size: 10rem;
		background-color: $color-gray;
		background-size: cover;
		background-repeat: no-repeat;
		color: $color-white;
		width: $bubble-size;
		height: $bubble-size;
		border-radius: 100%;
		vertical-align: middle;
		line-height: 2.7rem;
		text-align: center;
		word-wrap: break-word;
		font-size: 3.2rem;
		padding: 1.1rem;
	}

	&__rectangle{
		$rect-height: 30rem;
		background-color: $color-gray;
		background-size: cover;
		background-repeat: no-repeat;
		color: $color-white;
		width: 100%;
		height: $rect-height;
		vertical-align: middle;
		line-height: 2.7rem;
		text-align: center;
		word-wrap: break-word;
		font-size: 3.2rem;
		padding: 1.1rem;
	}

	&__cta_link{
		font-weight: 700;
		border-radius: 3px;
		text-transform: uppercase;
		font-size: 1.5rem;
		padding: 1rem 1.5rem;
		background-color: #323232;
		color: #fff!important;
		//margin-top: 3rem;
	}

	&__desc{
		border: 1px solid #ccc;
		border-top: none;
		padding: 3rem 3rem 3rem;
		background: #fff;
	}

	&__ct{
		display: none;
	}
	@media (max-width: $breakpoint--md) {
		&__link_text{
			display: none;
		}

		&__cta_link{
			display: none;
		}

		&__ct{
			margin-top: 2rem;
			display: inline-block;
			font: normal normal normal 14px/1 FontAwesome;
			font-size: 3rem;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			float: right;
		}
		&__ct:before{
			content: "\f09a";
		}

		&__rectangle{
			$rect-height: 40rem;
			height: $rect-height;
		}
	}

	&:hover {
		color: #000;
		text-decoration: none !important;
	}
	&__content {
		padding-left: 2rem;
	}
	&__date {
		font-size: 1.5rem;
	}

	&__title {
		text-transform: uppercase;
		font-size: 2.4rem;
		font-weight: $font-weight-bold;
	}

	&__location {
		color: $color-gray;
		opacity: .5;
		text-transform: uppercase;
	}

	&__green {
		.a-event__bubble {
			background-color: $color-green;
		}
		.a-event__title {
			color: $color-green;
		}
	}

	&__blue {
		.a-event__bubble {
			background-color: $color-blue;
		}
		.a-event__title {
			color: $color-blue;
		}
	}

	&__red {
		.a-event__bubble {
			background-color: $color-red;
		}
		.a-event__title {
			color: $color-red;
		}
	}

	&__date_white{
		font-weight: 700;
		width: 50px;
		background: #fff;
		float: right;
		padding: 0 10px 5px;
	}
	.a-event a{
		text-decoration: none;
		color: inherit;
	}
	.a-event a:hover{
		text-decoration: none;
		color: inherit;
	}
}
.gm-style-iw{
	z-index: -9999 !important;
}

@media (min-width: 768px) {
	.dropdown{
		left: 28%;
	}
}
.dropdown{
	position: relative;
	margin: 0 auto;
	z-index: 10;
	display: inline-block;
	min-width: 320px;
	text-align: left;

	&.is-open{
		background-color: #cccccc;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5);
		z-index: 11;

		a.toggle{
			h3{

			}
			&:after{
				content: "\2304";
				transform: rotate(180deg);
				float: right;
				font-weight: bolder;
				font-size: 26px;
				margin-left: 5px;
				margin-top: -5rem;
			}
		}
		.panel-wrap{
			display: block;
		}
	}

	a.toggle{
		position: relative;
		display: block;
		padding: 0 25px;
		height: 70px;

		h3{
			display: block;
			text-transform: uppercase;
			letter-spacing: 0.04em;
			padding-right: 25px;
		}

		/* caret */
		&:after{
			content: "\2304";
			float: right;
			font-weight: bolder;
			font-size: 26px;
			margin-left: 5px;
			margin-top: -6.5rem;
		}
	}
	.panel-wrap{
		display: none;
		width: 100%;
		background-color: #ccc;
	}
	ul.panel{
		display: none;
		font-size: 16px;
		letter-spacing: 0.04em;
		padding: 20px 0;

		&.is-open{
			display: block;
		}

		>li{
			display: block;

			&.active{
				>a{
					color: rgb(229, 62, 61);
				}
			}

			>a{
				display: block;
				padding: 10px 25px;

				&:hover{
					opacity: .6;
				}
			}
		}
	}
} /* dropdown */

.dropdown-filter {
	position: relative;
	z-index: 10;
	display: inline-block;
	min-width: 320px;
	text-align: left;

	&.is-open {
		background-color: white;
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, .5);
		z-index: 11;

		.panel-wrap {
			display: block;
		}
	}

	a.toggle {
		position: relative;
		display: block;
		padding: 0 25px;
		height: 70px;

	}

	.panel-wrap {
		display: none;
		width: 100%;
		background-color: white;
		box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .5);

		/* line */
		&:before {
			display: block;
			content: "";
			height: 1px;
			border-bottom: 1px solid gray(232);
		}
	}

	ul.panel {
		display: none;
		background-color: white;
		padding: 20px 0px;

		&.is-open {
			display: block;
		}

		> li {
			display: block;
			padding: 0 25px;
		}
	}
}/* ul.panel */
.excerpt_event{
	padding-bottom: 5rem;
}
