.m-header-sections {

	a{
		text-decoration: none;
	}

	@media (min-width: $breakpoint--md) {
		height: calc(100vh - 120px);
	}

	&__section {
		background-size: cover;
		background-position: center;
		display: block;
		text-decoration: none;
		filter: contrast(120%) brightness(115%);

		@media (min-width: $breakpoint--md) {
			height: calc(65vh);
		}

		&--small {
			height: 50%;
		}

		&--small-organisatie{
			height: 100%;
		}


		img {
			max-width: 40vw;

			@media (min-width: $breakpoint--md) {
				max-width: 20vw;
			}
		}

		&__overlay {
			background-color: transparentize($color-gray, .55);
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
		}

		&__title {
			font-size: 4rem;
			color: $color-white;
			text-transform: uppercase;
			font-weight: 700;
			text-align: center;
			padding: 4rem;
		}
		&__video_title{
			font-size: 4rem;
			color: $color-white;
			text-transform: uppercase;
			font-weight: 700;
			text-align: center;
			padding: 4rem;
		}
		&__info{
			margin-bottom: 5rem;
		}
		&__infolijn{
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&__infolijn p{
			display: inline-grid;
		}
		&__infolijn ul li{
			list-style: none;
		}
		&__infolijn i{
			color: #6a8ac5;
			padding-right: 1rem;
			padding-bottom: 2rem;
		}
		&__custom_underline {
			position: relative;
			text-decoration: none;
		}


		&__custom_underline::after {
			content:"";
			position:absolute;
			top:140%;
			left:20%;
			width:50px;
			height:2px;
			margin-top:-1px;
			background:#6a8ac5;
		}

		&__title{
			text-align: center;
		}
	}
}

.video-content iframe,
.video-content object,
.video-content embed {
	z-index: -100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position:absolute;
}
.overlay_video{
	background-color: transparentize($color-gray, .25);
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

@media (max-width: 767px) {
	.menu-item-2045{
		background-color: inherit;
		padding: 16px;
		margin: 0 auto;
		font-size: 16px;
		border: none;
	}
	.menu-item-2045{
		position: relative;
		display: inline-block !important;
	}
	.menu-item-1565 {
		background-color: inherit;
		padding: 16px;
		margin: 0 auto;
		font-size: 16px;
		border: none;
	}

	.menu-item-1565 {
		position: relative;
		display: inline-block !important;
	}

	.nav-sub {
		display: none;
		background-color: #323232;
		color: #fff;
		width: 250px;
		margin: 0 auto;
	}
	.nav-sub ul{
		padding: 0 !important;
		width: 250px;
		margin: 0 auto;
	}
	.nav-sub li{
		list-style: none;
		color: #fff;
		width: 250px;
		margin: 0 auto;
	}

	.nav-sub a {
		color: black;
		padding: 5px 5px;
		display: block;
		width: 250px;
		margin: 0 auto;
	}


	.nav-sub li:hover a{background-color: darkgray !important;}
	.nav-sub a:after {opacity: 0 !important;}
	.menu-item-1565:hover .nav-sub {display: block;}
	.menu-item-2045:hover .nav-sub {display: block;}
	.menu-item-1565:hover .menu-item-1387 {background-color: darkgray;}
	.menu-item-2045:hover .menu-item-1387 {background-color: darkgray;}

}
@media (min-width: 768px) {
	.menu-item-2045 {
		background-color: inherit;
		padding: 16px;
		font-size: 16px;
		border: none;
		z-index: 100;
	}
	.menu-item-1565 {
		background-color: inherit;
		padding: 16px;
		font-size: 16px;
		border: none;
		z-index: 100;
	}
	.menu-item-2045 {
		position: relative;
		display: inline-block !important;
	}
	.menu-item-1565 {
		position: relative;
		display: inline-block !important;
	}

	.nav-sub {
		display: none;
		position: absolute;
		background-color: #323232;
		color: #fff;
		width: 100%;
	}
	.nav-sub ul{
		padding: 0 !important;
	}
	.nav-sub li{
		list-style: none;
		color: #fff;
	}

	.nav-sub a {
		color: black;
		padding: 12px 16px;
		display: block;
	}

	.nav-sub li:hover a{background-color: darkgray !important;}
	.nav-sub a:after {opacity: 0 !important;}
	.menu-item-1565:hover .nav-sub {display: block;}
	.menu-item-2045:hover .nav-sub {display: block;}
}
