.m-datepicker {
	background: #fff;
	box-shadow: $box-shadow;
}

.m-datepicker .ui-widget {
	font-family: $font-family-base;
	text-transform: uppercase;
	border: none;
	border-radius: 0;
}

.m-datepicker .ui-datepicker {
	padding: 1rem;
}

.m-datepicker .ui-datepicker-header {
	border: none;
	background: transparent;
	font-weight: normal;
}

.m-datepicker .ui-datepicker .ui-datepicker-title {
	margin-top: .4em;
	margin-bottom: .3em;
	font-size: $font-size-base;
	font-weight: $font-weight-bold;
	color: $color-gray;
}

.m-datepicker .ui-datepicker .ui-datepicker-prev-hover,
.m-datepicker .ui-datepicker .ui-datepicker-next-hover,
.m-datepicker .ui-datepicker .ui-datepicker-next,
.m-datepicker .ui-datepicker .ui-datepicker-prev {
	top: .9em;
	border:none;
}

.m-datepicker .ui-datepicker .ui-datepicker-prev-hover {
	left: 2px;
}

.m-datepicker .ui-datepicker .ui-datepicker-next-hover {
	right: 2px;
}

.m-datepicker .ui-datepicker .ui-datepicker-next span,
.m-datepicker .ui-datepicker .ui-datepicker-prev span {
	cursor: pointer;
}

.m-datepicker .ui-datepicker .ui-datepicker-next span {
	transform: rotate(180);
}

.m-datepicker .ui-datepicker table {
	margin: 0;
}

.m-datepicker .ui-datepicker th {
	padding: 1em 0;
	color: $color-gray;
	font-size: 1rem;
	text-transform: uppercase;
	font-weight: $font-weight-bold;
	border: none;
	border-top: 1px solid #3a414d;
}


.m-datepicker .ui-datepicker td a {
	color: $color-gray;
	font-size: 1rem;
	font-weight: $font-weight-bold;
	padding: 1rem;
}

.m-datepicker .ui-datepicker td {
	background: $color-white;
	border: none;
	padding: 0;
}

.m-datepicker td .ui-state-default {
	background: transparent;
	border: none;
	text-align: center;
	padding: .5em;
	margin: 0;
	font-weight: normal;
	color: #efefef;
	font-size: 16px;
}

.m-datepicker .ui-state-disabled  {
	opacity: 1;
}

.m-datepicker .ui-state-disabled .ui-state-default {
	color: #fba49e;
	opacity: 0;
}

.m-datepicker td .ui-state-active,
.m-datepicker td .ui-state-hover {
	background: $color-green;
	color: $color-gray;
}
