//GROUP ALL GLOBAL VARIABLES

//FONT PATH
$asset-path:                        '../';
$image-url-path:                    $asset-path + 'images/';
$font-url-path:                     $asset-path + 'fonts/';
$iconfontpath:                      $asset-path + 'fonts/icomoon/';

$font-size-small:                   1.8rem !default; // 12px
$font-size-base:                    1.8rem !default; // 16px
$font-size-medium:                  2.5rem !default; // 24px
$font-size-large:                   2.8rem !default; // 32px

$font-size-h1:                      4.8rem !default; // 32px
$font-size-h2:                      3.8rem !default; //24px
$font-size-h3:                      2.4rem !default; //18px
$font-size-h4:                      1.9rem !default;

$font-weight-regular:               300 !default;
$font-weight-semi-bold:             400 !default;
$font-weight-bold:                  700 !default;

$line-height-base:                  1.6 !default;
$line-height-computed:              floor(($font-size-base * $line-height-base)) !default;

$headings-line-height:              1.1 !default;
$box-shadow:						1rem 1rem 1.9rem -1.3rem rgba(0,0,0,.75) !default;
$box-shadow-hover:						1rem 1rem 3rem -1.3rem rgba(0,0,0,.85) !default;
