.m-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: block;
    background-color: rgba(0,0,0,0.4);
    z-index: 9000;
    opacity: 1;
    transition: all .5s;

    &__close {
        color: $color-white;
        float: right;
        margin-right: 3.5rem;
        margin-top: 1rem;
        font-size: 3rem;
        cursor: pointer;
    }

    &__placeholder {
        background-color: $color-white;
        padding: 1rem;
        border-radius: .5rem;
        width: 80vw;
        height: auto;
        margin: 20vh auto 0 auto;
    }

    &.closed {
        opacity: 0;
        visibility: hidden;
    }
}
