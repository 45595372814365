.m-map {
	position: relative;
	&__map {
		height: 100vh;
		width: 100%;

		.leaflet-popup-content-wrapper {
			border-radius: 0;
			.leaflet-popup-content {
				font-family: $font-family-base;
				font-size: 1.5rem;
				margin: 2rem;
				width: auto;

				a {
					cursor: pointer;
					margin-top: 3rem;
				}
			}
		}
	}

	&__overlay {
		position: absolute;
		top: 70%;
		right: 0;
		z-index: 400;
		width: 100vw;
		height: 30vh;
		background-color: transparentize($color-white, .5);
h1 {
	display: none;
}
		@media (min-width: $breakpoint--md) {
			top: 0;
			right: 0;
			z-index: 400;
			width: 30vw;
			height: 100vh;

			h1 {
				display: inline-block;
			}
		}

		ul {
			list-style: none;
			li {
				label {
					font-weight: $font-weight-bold;
					margin-left: 1rem;
					cursor: pointer;
				}
			}
		}
	}
}
.acf-map {
	width: 100%;
	height: 400px;
	border: #ccc solid 1px;
	margin: 0 auto;
	margin-bottom: 5rem;
}
.m-article__description span{
	display: block;
	margin-top: 3rem;
	text-align: center;
}

@media (max-width: 1023px) {
	.contact-image{
		display: none;
	}
	.contact-image-2{
		display: none;
	}
}

/* GOOGLE MAP */
.gm-style-iw-d img{
	width:20%;
}
#map button, #map .button {
	min-width: auto;
}
#map{
	max-height: 520px;
}
.map-homepage .container {
	background-color: #fff;
}
.map-homepage .map-wrap {
	position: relative;
	display: block;
	padding-bottom: 48%;
}
@media (max-width: 767px) {
	.map-homepage .map-wrap {
		position: relative;
		display: block;
		padding-bottom: 100%;
	}
}
@media (min-width: 768px) and (max-width:1200px) {
	.map-homepage .map-wrap {
		position: relative;
		display: block;
		padding-bottom: 48%;
	}
}
@media (min-width: 1300px) and (max-width: 1499px) {
	.map-homepage .map-wrap {
		padding-bottom: 43%;
	}
}
@media (min-width: 1500px) {
	.map-homepage .map-wrap {
		padding-bottom: 39%;
	}
}
.map-homepage .map-wrap #map {
	display: block;
	position: absolute;
	overflow: hidden;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}
.map-homepage .panel {
	display: inline-block;
	right: 5px;
	z-index: 10;
	color: black;
	background-color: #F0F0F0;
	padding: 0 20px;
}
.map-homepage .panel h6 {
	color:#fff;
}
.title-map{
	padding: 1rem;
}
.map-homepage .search-wrap {
	position:absolute;
	top: 6px;
	left: 200px;
	background-color: white;
}
.map-homepage
{
	position:relative;
}
.map-homepage .search-wrap .search input[type="text"] {
	width:83%;
	border-radius: 0px;
}
.map-homepage .search-wrap .search a {
	display: inline-block;
	position: absolute;
	top: 0px;
	right: 0px;
	width: 40px;
	height: 40px;
	line-height: 45px;
	text-align: center;
}
.map-homepage .search-wrap .search a:after {
	font-family: 'icomoon';
	content: "\e908";
	font-size: 15px;
	position: relative;
	top: 1px;
	font-weight: bold;
}
input[type="checkbox"], input[type="radio"] {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0;
}
.toggles{
	list-style:none;
}

input.chk-btn {
	display: none;
}
input.chk-btn + label {
	border: 1px solid black;
	text-transform: uppercase;
	font-size: 15px;
	padding: 0;
	cursor: pointer;
	border-radius: 5px;
	color: black;
}
input.chk-btn:not(:checked) + label:hover {
	box-shadow: 2px 3px 1px;


}
input.chk-btn + label:active,
input.chk-btn:checked + label {
	background: darkgray;
}

/*    checkbox*/
@-moz-keyframes
dothabottomcheck {  0% {
	height: 0;
}
	100% {
		height: 15px;
	}
}
@-webkit-keyframes
dothabottomcheck {  0% {
	height: 0;
}
	100% {
		height: 15px;
	}
}
@keyframes
dothabottomcheck {  0% {
	height: 0;
}
	100% {
		height: 15px;
	}
}
@keyframes
dothatopcheck {  0% {
	height: 0;
}
	50% {
		height: 0;
	}
	100% {
		height: 35px;
	}
}
@-webkit-keyframes
dothatopcheck {  0% {
	height: 0;
}
	50% {
		height: 0;
	}
	100% {
		height: 60px;
	}
}
@-moz-keyframes
dothatopcheck {  0% {
	height: 0;
}
	50% {
		height: 0;
	}
	100% {
		height: 20px;
	}
}

input[type=checkbox] { display: none;}

.map-span{
	margin-left: 5px; color: black;
}

.check-box {
	height: 20px;
	width: 20px;
	top: 5px;
	margin-top: 20px;
	background-color: transparent;
	border: 1px solid black;
	border-radius: 5px;
	position: relative;
	display: inline-block;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-moz-transition: border-color ease 0.2s;
	-o-transition: border-color ease 0.2s;
	-webkit-transition: border-color ease 0.2s;
	transition: border-color ease 0.2s;
	cursor: pointer;
}

.check-box::before,
.check-box::after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	position: absolute;
	height: 0;
	width: 3px;
	background-color: black;
	display: inline-block;
	-moz-transform-origin: left top;
	-ms-transform-origin: left top;
	-o-transform-origin: left top;
	-webkit-transform-origin: left top;
	transform-origin: left top;
	border-radius: 5px;
	content: ' ';
	-webkit-transition: opacity ease .5;
	-moz-transition: opacity ease .5;
	transition: opacity ease .5;
}

.check-box::before {
	top: 17px;
	left: 10px;
	-moz-transform: rotate(-150deg);
	-ms-transform: rotate(-150deg);
	-o-transform: rotate(-150deg);
	-webkit-transform: rotate(-150deg);
	transform: rotate(-150deg);
}

.check-box::after {
	top: 3px;
	left: 2px;
	-moz-transform: rotate(-25deg);
	-ms-transform: rotate(-25deg);
	-o-transform: rotate(-25deg);
	-webkit-transform: rotate(-25deg);
	transform: rotate(-25deg);
}

input[type=checkbox]:checked + .check-box,
.check-box.checked { border-color: black; }

input[type=checkbox]:checked + .check-box::after,
.check-box.checked::after {
	height: 50px;
	-moz-animation: dothabottomcheck 0.2s ease 0s forwards;
	-o-animation: dothabottomcheck 0.2s ease 0s forwards;
	-webkit-animation: dothabottomcheck 0.2s ease 0s forwards;
	animation: dothabottomcheck 0.2s ease 0s forwards;
}

input[type=checkbox]:checked + .check-box::before,
.check-box.checked::before {
	height: 120px;
	-moz-animation: dothatopcheck 0.4s ease 0s forwards;
	-o-animation: dothatopcheck 0.4s ease 0s forwards;
	-webkit-animation: dothatopcheck 0.4s ease 0s forwards;
	animation: dothatopcheck 0.4s ease 0s forwards;
}


/*    Close icon infowindow*/
.info-window img{
	float: right; margin: -7rem 0 1.5rem 1.5rem;
}
.link{
	text-decoration: underline;
}

.gm-ui-hover-effect{
	background: #fff !important;
	top: 0 !important;
	right: 0 !important;
	border: 1px solid #fff !important;
	border-radius: 50%;
	width: 30px !important;
	height: 30px !important;
	opacity: 1;}
.gm-ui-hover-effect img{margin: -8px 6px !important;
	width: 16px !important;
	height: 16px !important;
	content:url("http://icons.iconarchive.com/icons/custom-icon-design/mono-general-1/24/delete-icon.png") !important;}
.gm-style .gm-style-iw {
	width: 400px !important;
	left: 0px !important;
	padding-left: 10px;
}
