.a-breadcrumbs {
	margin: 2rem 0 -1rem 0;
	padding: 0;

	a{
		text-decoration: none;
	}

	&__view{
		padding: 3rem;
	}
	&__item {
		list-style: none;
		display: inline-block;
		&:after {
			content: ' ';
			$breadcrumb-arrow: 1.5rem;
			width: $breadcrumb-arrow;
			height: $breadcrumb-arrow;
			margin: .6rem .5rem 0 1rem;
			background-size: cover;
			display: inline-block;
			background-image: url('./images/arrow-datepicker.png');
			transform: rotate(180deg);
		}
		&:last-child {
			&:after {
				display: none;
			}
		}
		&__link {
			text-transform: uppercase;
		}


	}
}


