.a-filter {
	background-color: $color-gray;

	&__label {
		position: relative;
		display: inline-block;
		cursor: pointer;
		p {
			color: $color-white;
			display: inline-block;
			&:after {
				content: ' ';
				width: 0;
				height: 0;
				position: absolute;
				top: 45%;
				right: 1rem;
				margin-right: -3rem;
				z-index: 9;
				border-left: .7rem solid transparent;
				border-right: .7rem solid transparent;

				border-top: .7rem solid $color-white;
			}
		}
	}

	&__dropdown {
		background-color: $color-white;
		position: absolute;
		margin-left: -20.7rem;
		z-index: 9;
		width: 37rem;
		left: 0;
		padding:1.5rem;
		box-shadow: $box-shadow;
		margin-top: -5px;

		&:before {
			content: ' ';
			width: 0;
			height: 0;
			position: absolute;
			right: 1.8rem;
			z-index: 9;
			margin-top: -2.5rem;
			border-left: 2rem solid transparent;
			border-right: 2rem solid transparent;
			border-bottom: 2rem solid $color-white;
		}

		&.closed {
			display: none;
		}

		&__list {
			list-style: none;
			padding-left: 1rem;
			margin: 0;
			label {
				font-size: 1.6rem;
				text-transform: uppercase;
				cursor: pointer;
				&:before {
					content: ' ';
					width: 1.4rem;
					height: 1.4rem;
					//display: block;
					border: 1px solid $color-gray;
					display: inline-block;
					margin-right: 1rem;
					transition: background-color .3s ease-in;
				}
			}

			input[type=checkbox] {
				display: none;
			}


			input[type=checkbox]:checked + label:before {
				background-color: $color-gray;
				font-style: normal;
			}
		}
	}
}
.a-filter__label p{
	margin-top: 10px;
	margin-left: 5rem;
}
