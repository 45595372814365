
.container-fluid {
	margin-right: auto;
	margin-left: auto;
	padding-left: 0px;
	padding-right: 0px; }
.container-fluid:before, .container-fluid:after {
	content: " ";
	display: table; }
.container-fluid:after {
	clear: both; }
.carouselTicker {
	overflow: hidden;
	float: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.carouselTicker__list {
	margin: 15px 80px;
	list-style-type: none;
	overflow: hidden;
}

.carouselTicker__item {
	margin: 0;
	float: left;
	margin-left: 5px;
	width: 150px;
	height: 200px;
	color:#fff;
	line-height: 60px;
	text-align: center;
	padding: 20px;
}

.carouselTicker__item--pic{
	height: 150px;
	background-size: contain;
	background-repeat: no-repeat;
}

.align-ul{
	margin: 0 auto;
	text-align: center;
	padding: 0;
	max-width: 1100px;
}
.align{
	display: inline-block;
	vertical-align: top;
	max-width: 200px;
	margin: 5px;
	display: -moz-inline-stack;
	display: inline-block;
}

.partner-img{
	display: inline-block;
	max-width: 200px;
	padding-right: 20px;
}

.partner-title{
	padding-bottom: 5rem;
}

.m-partner{
	padding: 2rem 2rem 6rem 2rem;
	background-color: rgba(50,50,50,.1);
}

.m-partner-white{
	padding: 2rem 2rem 6rem 2rem;
}

.partner-sub-title{
	text-align:center;
	padding-bottom: 2rem;
}

@media (max-width: 451px) {
	.p-title{
		margin: 0 auto;
	}
}
