img {
    display: block;
    width: 100%;
}

.verhaal-img{
    border-radius: 50%;
    height: 250px;
    width: 250px;
    box-shadow: 1rem 1rem 1.9rem -1.3rem rgba(0,0,0,.75);
}
