//type styles

h1, h2, h3, h4, h5 {
	font-family: $headings-font-family;
	line-height: 1.3;
	font-weight: $headings-font-weight;

	&.bold {
		font-weight: $font-weight-bold;
	}
}

h1, .h1 {
	font-size: $font-size-h1;
	text-transform: uppercase;
	text-align: center;
	//margin: 12rem 0;
	margin: 6rem 0;
	&.bold {
		font-size: 3.5rem;
		margin: 12rem 0 0 0;
	}
}

h2, .h2 {
	font-size: $font-size-h2;
	font-weight: 300;
	color: $color-green;
	text-align: center;
	text-transform: uppercase;
	margin: 10rem 0;
	&.bold {
		font-size: 2.5rem;
		margin: 0 0 5rem 0;

	}
}

h3, .h3 {
	font-size: $font-size-h3;
	font-weight: $font-weight-regular;
}

h4, .h4 {
	font-size: $font-size-h4;
	font-weight: $font-weight-regular;
	font-family: $font-family-base;
	color: get-gray-color('dark');
}

p, a, li {
	color: $color-gray;
	font-weight: $font-weight-regular
}

a {
	text-decoration: none;
}

li {
	line-height: 3.5rem;
}

strong {
	font-weight: $font-weight-bold;
}

.text-align-left {
	text-align: left;
}

body {
	background-color: #f8f8f8;
}

.text-green {
	color: $color-green;
}

.text-red {
	color: $color-red;
}

.text-yellow {
	color: $color-yellow;
}

.text-blue {
	color: $color-blue;
}

.text-gray {
	color: $color-gray;
}

// .text--large, p.text--large {
//     font-size: $font-size-large;
// }
//
// .text--medium, p.text--medium {
//     font-size: $font-size-medium;
// }
//
// .text--small, p.text--small {
//     font-size: $font-size-small;
// }
//
// .text--center {
//     text-align: center;
//
//     @media (min-width: $breakpoint--sm) {
//         text-align: center;
//     }
// }
//
// .text--left {
//     text-align: left;
// }
//
// .text--right {
//     text-align: left;
//
//     @media (min-width: $breakpoint--sm) {
//         text-align: right;
//     }
// }
