.m-events {
	padding: 0 0 10rem 0;
	background-color: transparentize($color-gray, .9);
}
@media (max-width: 767px) {
#booking-menu{
	margin-left: 0;
}
}

@media (min-width: 1024px) {
	.event-title{
		margin-left: -8rem;
		margin-right: 10rem;
	}
	.event-title h1{
		margin-bottom: 0;
	}
	.img-event{
		margin-left: -5rem;
	}
}
