.a-card {
	margin: 1rem;
	background-color: $color-white;
	box-shadow: $box-shadow;
	transition: all .5s ease;
	display: flex;
	flex-direction: column;

	@media (min-width: 1020px) {
		height: 100%;
	}

	a{
		text-decoration: none;
	}

	&:hover {
		box-shadow: $box-shadow-hover;
	}

	&__header {
		background-image: url('images/work.jpg');
		background-size: cover;
		filter: contrast(120%) brightness(110%);
	}

	&__overlay {
		height: 20rem;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: transparentize($color-gray, .7);
	}

	&__title {
		color: $color-white;
		font-size: 3rem;
		text-align: center;
		text-transform: uppercase;
		font-weight: 700;
		padding: 4rem 6rem;
		line-height: 3.3rem;
	}

	&__content {
		padding: 3rem;
		display: flex;
		justify-content: space-between;
	}

	&__body {
		margin-top: 0;
	}

	&__cta {
		background-color: $color-gray;
		color: $color-white;
	}
}

.a-card-green {
	.a-card__overlay {
		background-color: transparentize($color-green, .3);
	}

	.btn {
		background-color: $color-green;
		color: $color-white;
	}
}
.a-card-red {
	.a-card__overlay {
		background-color: transparentize($color-red, .3);
	}

	.btn {
		background-color: $color-red;
		color: $color-white;
	}
}
.a-card-yellow {
	.a-card__overlay {
		background-color: transparentize($color-yellow, .3);
	}

	.btn {
		background-color: $color-yellow;
		color: $color-white;
	}
}
.a-card-blue {
	.a-card__overlay {
		background-color: transparentize($color-blue, .3);
	}

	.btn {
		background-color: $color-blue;
		color: $color-white;
	}
}

.a-news__content {
	width: 99%;
	@media (min-width: $breakpoint--md) {
		width: 50%;
	}
}
@media (max-width: 767px) {
	.a-card__content {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.a-card__content a {
		align-self: flex-end;
	}
}
@media (min-width: 768px) {
	.a-card__content {
		display: flex;
		flex-direction: column;
		//flex-wrap: wrap;
		justify-content: space-between;
		//height: 55%;
		height: 100%;
	}

	.a-card__content a {
		align-self: flex-end;
	}
}

@media (max-width:767px) {
	.a-card__cta {
		background-color: #323232;
		color: #FFF;
		margin-bottom: 15px;}
}

//styles for subtitles category
.subtitle-category-red{
	color: #F2696B;
}
.subtitle-category-yellow{
	color: #FED175;
}
.subtitle-category-blue{
	color: #6A8AC5;
}
.subtitle-category-white{
	color: #fff;
}
.subtitle-category-green{
	color: #5AC5BE;
}
//styles for buttons of category

.card-red{
	margin-top: 4em;
	background-color: #F2696B;
	color: #FFF !important;
}
.card-green{
	margin-top: 4em;
	background-color: #5AC5BE;
	color: #FFF !important;
}
.card-blue{
	margin-top: 4em;
	background-color: #6A8AC5;
	color: #FFF !important;
}
.card-yellow{
	margin-top: 4em;
	background-color: #FED175;
	color: #FFF !important;
}
.card-white{
	margin-top: 4em;
	background-color: #FED175;
	color: #FFF !important;
}
//styling for detailed vacatures
@media (min-width:320px ) and (max-width: 767px) {
	.vacatures_content{
		display: -webkit-box;
		display: -ms-flexbox;
		display: block;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		height: 60%;
		padding: 5rem;

	}
	.vacatures_content p{
		align-self: flex-end;
		margin-bottom: 3rem;
	}
	.vacatures_content a{
		align-self: flex-end;
		background-color: black;
		color: white;
	}

}
@media (min-width:768px ){
	.a-card__content p{
	hyphens: auto;
    }
	.a-card__content a{
		margin-bottom: 1rem;
	}
	.vacatures_content a{
		background-color: black;
		color: white;
	}
	.vacatures_content{
		height: 55%;
	}
}
.content-question p a, .a-card__content a{
	text-decoration: underline;
}
.vragen{
	background-color: #6a8ac5;
}
.vragen p{
	color: #fff;
}
.vragen ol{
	margin-top: -8rem;
	margin-bottom: -8rem;
}
.vragen ol li{
	color:#FFF;
}
.vragen ul li{
	color:#FFF;
}

.content-question>p, .content-question>a, .content-question>h2, .content-question>h3, .content-question p a, .content-question h3 a{
	color: #6a8ac5;
	margin-top: 0;
	text-align: center;
}

.accordion {
	background-color: #fff;;
	color: #fff;
	cursor: pointer;
	padding: 10px;
	width: 100%;
	text-align: left;
	border: 1px solid #fff;
	border-radius: 5px;
	outline: none;
	transition: 0.4s;
	margin-top: 1rem;
}

@media (max-width: 450px){
	.accordion {
		background-color: #fff;;
		color: #fff;
		cursor: pointer;
		padding: 10px;
		width: 95%;
		margin-left: 1rem;
		text-align: left;
		border: 1px solid #fff;
		border-radius: 5px;
		outline: none;
		transition: 0.4s;
		margin-top: 1rem;
	}
}

.accordion>.active, .accordion:hover {
	background-color: #fff;;
}

.circle-accordion{
	width: 50px;
	height: 50px;
	margin-top: 1.5rem;
	margin-left: 1rem;
	font-weight: 700;
	border-radius: 50%;
	border: 1px solid #f0f0f0;
	position: absolute;
	box-shadow:1rem 1rem 3rem -1.3rem rgba(0,0,0,.85);
	background-image: url('images/arrow-right.png');
	background-repeat: no-repeat;
	background-size: 20px 20px;
	background-position: center;
	content:"";
}

.accordion.active>.circle-accordion{
	width: 50px;
	height: 50px;
	margin-top: 1.5rem;
	margin-left: 1rem;
	font-weight: 700;
	border-radius: 50%;
	border: 1px solid #f0f0f0;
	position: absolute;
	box-shadow:1rem 1rem 3rem -1.3rem rgba(0,0,0,.85);
	background-image: url('images/arrow-accord-down.png');
	background-repeat: no-repeat;
	background-size: 20px 20px;
	background-position: center;
	content:"";
}

.panel {
		padding: 1rem 1.8rem 2rem 3rem;
		background-color: white;
		display: none;
		overflow: hidden;
		margin-bottom: 1rem;
		border-radius: 0 0 25px 25px;
		margin-top: -12px;
	}
.blockquote{
	position: relative;
	margin-left: 7rem;
	height: auto;
	background-color: rgba(50,50,50,.1);
	margin-bottom: 5rem;
	box-shadow: 1rem 1rem 1.9rem -1.3rem rgba(0,0,0,.75);
}
.blockquote:before{
	content: "";
	position: absolute;
	top: -4rem;
	left: -4rem;
	display: block;
	background-image: url(images/quote.svg);
	width: 4.6rem;
	height: 4.6rem;
}

.blockquote h3{
	font-style: italic;
	text-align: center;
	padding-top: 10%;
	padding-bottom: 10%;
}

.verhaal-img-div{
	float: right;
	margin-top: -7rem;
}

.verhaal-bottom{
	border-bottom: 1px solid #cccccc;
	margin-bottom: 0;
}

.verhaal-data{
	font-weight: bolder;
	float: right;
	margin-top: -6rem;
}

.verhaal-text:first-child{
	margin-top: 0;
}

@media (max-width: 550px) {
	.verhaal-img{
		width: 200px;
		height: 200px;
	}
	.blockquote:before{
		content: "";
		position: absolute;
		top: -2rem;
		left: -4rem;
		display: block;
		background-image: url(images/quote.svg);
		width: 4.6rem;
		height: 4.6rem;
	}
}
@media (max-width: 992px) {
	.verhaal-img-div{
		float: right;
		margin-top: -7rem;
	}
}

.woningen{
	margin-top: 2.5rem;
	margin-bottom: 2.5rem;
}
