.m-article {
	&__content {
		padding: 0 7.5rem 5rem 0;
		word-break: break-word;
		margin-bottom: 7rem;

		@media (max-width: 768px) {
			padding: 0 7.5rem 0 5rem;
		}

		&--right {
			@media (min-width: 768px) {
				padding: 0 0 0 5rem;
			}
		}

		&__title {
			margin-top: 5rem !important;
		}
		h2 {
			margin-top: 1rem !important;
		}
	}

	&__content_verhaal{
		padding: 3rem 7.5rem 0 0;
		word-break: break-word;
		margin-top: 2rem;
		margin-bottom: 3rem;
		.blockquote {
			text-align: left;
			padding: 2rem;
			&:before {
				top: -1rem;
				left: -1rem;
			}
			h3 {
				text-align: left;
				padding: 0;
			}
		}
		.verhaal-img-div {
			padding-left: 1rem;
			margin-top: 0;
		}
		.verhaal-data {
			font-weight: bolder;
			text-align: right;
			margin-top: 0 ;
		}
	}
	&__description{
		padding-top: 2rem;
		padding-bottom: 5rem;
		margin: 0 auto;
		max-width: 100%;
		text-align: justify;
	}
	&__description li{
		color: #fff;
	}
	&__faq{
		margin: 3rem 0;
	}
}
.search-faq{
	margin-bottom: 5rem;
}
