.m-form {

	height: 100%;
	width: 100%;
	margin: 0;
	max-width: 100%;
	label {
		display: block;
		font-size: $font-size-small;
		margin-bottom: 1rem;
	}

	input[type="text"], input[type="email"], input[type="number"], textarea {
		padding: 1rem;
		border: 1px solid $color-gray;
		border-radius: 4px;
		margin-bottom: 1rem;
		width: 100%;
	}
	input[type="text"], input[type="email"], input[type="number"], textarea {
		width: 100%;
		@media (min-width: $breakpoint--md) {
			max-width: 44rem;
		}
	}
}
body .gform_wrapper form .gform_body ul, body .gform_wrapper form .gform_body ul li {
	list-style-type: none!important;
	list-style-image: none!important;
	list-style: none!important;
	width: 100%;
	background-image: none!important;
	background: none!important;
	padding: 0!important;
	margin: 0!important;
	border: none!important;
}

@media (min-width:1024px) {
	body	.gform_wrapper .gform_body{
		width: 200%;
	}

}
.gf-recaptcha-div{
	display: none !important;
}

.validation_error{
	display: none !important;
}
.gform_wrapper .validation_message{
	color: red !important;
}
.gform_wrapper .field_description_below .gfield_description {
	padding-top: 0 !important;
}
body img.gform_ajax_spinner {
	display: none !important;
}

.wpbc_structure_form{
	display: none;
}

#gform_submit_button_3{
	margin-left: 5rem;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px !important;
	padding: 0.5rem 1.5rem !important;
	background-color: #323232;
	color: #fff;
}

#gform_wrapper_3{
	margin-top: 0 !important;
}

.subscribe{
	text-transform:uppercase;
	text-align:center;
	font-weight:bolder;
	font-size:1.4rem;
	padding: 2rem;
}
