.m-header {
	padding: 2rem 0;
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);

	a {
		text-align: center;
		text-decoration: none;
		width: 100vw;
		@media (min-width: $breakpoint--md) {
			text-align: left;
			width: auto;
		}
		img {
			display: inline;
		}
	}

	&__logo {
		max-width: 250px;
		height: auto;

	}

 	&__menu {
		transition: all .5s;
		width: 100%;

		&.closed {
			overflow: hidden;
			max-height: 0;

			li {
				opacity: 0;
			}
		}

		@media (min-width: $breakpoint--md) {
			max-height: auto;
			width: auto;

			&.closed {
				overflow: visible;
				max-height: 400px;

				li {
					opacity: 1;
				}
			}
		}

	}

	&__menu {
		list-style: none;
		padding-left: 0;
		margin-top: 2rem;
		@media (min-width: $breakpoint--md) {
			margin: 0;
		}
		li {
			opacity: 1;
		}
		a{
			text-decoration: none;
		}


		.fa-search {
			cursor: pointer;
			width: 100%;
			display: none;
			@media (min-width: $breakpoint--md) {
				display: inline;
			}
			a {
			text-align: center;
			}
		}
		&__item {
			display: inline-block;
			width: 100%;
			text-align: center;

			@media (min-width: $breakpoint--md) {
				display: inline;
				margin: 0 1.5rem;
				width: auto;
			}

			&__link {
				font-size: $font-size-base;
				position: relative;


				&:after {
					opacity: 0;
					content: ' ';
					width: 0%;
					height: 2px;
					background-color: $color-gray;
					position: absolute;
					bottom: -7px;
					left: 0;
					transition: all 0.3s ease-in;
				}

				&:hover {
					&:after {
						opacity: 1;
						width: 100%;
					}
				}

				img {
					max-width: 1.5rem;
				}
			}
		}
		&__toggle {
			font-weight: $font-weight-bold;
			padding-top: 1rem;
			@media (min-width: $breakpoint--md) {
				display: none;
			}
		}

	}
}
