.page-numbers {
	list-style: none;
}
.page-numbers li{
	list-style: none;
	display: inline-block;
	text-align: center;
	font-style: inherit;
	font-size: 18px;
	text-indent: 4rem;
}
.pagination{
	padding: 5rem;
	margin: 0 auto;
}
