$l: 'section';

.#{$l} {
    padding: 15px 0;

    &__title {
        margin: 0 auto;
        text-align: center;
    }

    @media (min-width: $breakpoint--md) {
        padding: 25px 0;

        &__title {
            max-width: 70%;
        }
    }
}

.bg--lightgrey {
    background-color: get-gray-color('light');
}

.bg--darkgrey {
    background-color: get-gray-color('dark');
}

.bg--mixed {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        height: 40%;
        background-color: get-gray-color('light');
    }
}
