.a-news-card {
	display: flex;
	margin: 1rem;
	min-height: 29rem;
	height: 95%;
	background-color: $color-white;

	@media (min-width: $breakpoint--md) {
		display: flex;
	}

	a{
		text-decoration: none;
	}

	box-shadow: $box-shadow;
	transition: all .5s ease;

	&:hover {
		box-shadow: $box-shadow-hover;
	}

	&__header {
		background-size: cover;
		@media (min-width: $breakpoint--md) {
			width: 35%;
		}
		display: inline-block;
	}

	&__overlay {

		@media (min-width: $breakpoint--md) {
		height: 100%;
	}
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 2rem;

		background-color: transparentize($color-gray, .8);
		filter: contrast(120%) brightness(110%);
	}

	&__title {
		color: $color-white;
		font-size: 1.6rem;
		text-align: center;
		text-transform: uppercase;
		font-weight: 700;
		line-height: 3.3rem;

	}

	&__body {
		p {
			margin-top: 0;
		}
		&__date {
			font-weight: 900;
			font-size: 1.3rem;
			text-transform: uppercase;
		}
	}

	&__content {
		display: flex;
        flex-direction: column;
		justify-content: space-between;
		padding: 2.5rem;
		height: 100%;
	}

	&__cta {
		font-size: 1.4rem;
		align-self: flex-end;
		text-decoration: none;
		@media (min-width: $breakpoint--md) {
			font-size: 1.1rem;
		}
	}

	&--large {
		.a-news-card__header {
			width: 100%;
			@media (min-width: $breakpoint--md) {
				width: 50%;
			}
		}

		.a-news-card__content {
			width: 99%;

@media (min-width: $breakpoint--md) {
			width: 50%;
		}
		}
	}

	.a-news-card__content a:hover{
		background-color: black;
		color: white;
	}

	@media (min-width:768px) {
	.a-news-card__content{
		display: flex;
		flex-direction: column;
		word-break: break-word;
	}
	}
	@media (max-width:767px) {
	.a-news-card__content{
		height: 35%;
	}
}
@media (max-width: 670px) {
	.a-news-card{
		display: inline-block;
	}
}
	@media (min-width: 667px) and (max-width: 767px) {
		.a-news-card__content{
			height: 95%;
		}
	}

}
