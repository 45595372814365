/* GOOGLE MAP */
.gm-style-iw-d img{
  width:40%;
  height: 42%;
}
.wonen_img{
  width: 40% !important;
  height: 55% !important;
  top: -2rem !important;
}
.wonen_img_2{
  width: 40% !important;
  height: 78% !important;
  top: -2rem !important;
}
#map button, #map .button {
  min-width: auto;
}
.map-homepage .container {
  background-color: #fff;
}

.map-homepage .map-wrap #map {
  display: block;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.panel{
  width: 100%;
  padding-bottom: 3rem !important;
}
.map-homepage .title-wrap,
.map-homepage .butt-wrap {
  text-align: center;
  padding: 0 0 20px 0;
}
.map-homepage .search-wrap {
  position:absolute;
  top: 6px;
  left: 200px;
  background-color: white;
}
.map-homepage
{
  position:relative;
}
.map-homepage .search-wrap .search input[type="text"] {
  width:83%;
  border-radius: 0;
}
.map-homepage .search-wrap .search a {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  line-height: 45px;
  text-align: center;
}
.map-homepage .search-wrap .search a:after {
  font-family: 'icomoon';
  content: "\e908";
  font-size: 15px;
  position: relative;
  top: 1px;
  font-weight: bold;
}
input[type="checkbox"], input[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

#buttons{
  padding-top: 0;
}
#buttons label, #buttons-2 label{
  margin: 0.2rem;
  padding: 1.3rem;
  display: inline-block;
  color: #fff;
  font-weight: 600;
}
.leven label{
  background-color: #fed175;
}
.wonen label{
  background-color: #5ac5be;
}
.werken label{
  background-color: #f2696b;
}
.leven-chk + label{
  border: 1px solid #fed175 !important;
}
.leven-chk + label:hover{
  transform: scale(1.15);
  transition: all 0.3s ease-in;
  box-shadow: none !important;
}
.wonen-chk + label{
  border: 1px solid #5ac5be !important;
}
.wonen-chk + label:hover{
  transform: scale(1.15);
  transition: all 0.3s ease-in;
  box-shadow: none !important;
}
.werken-chk + label{
  border: 1px solid #f2696b !important;
}
.werken-chk + label:hover{
  transform: scale(1.15);
  transition: all 0.3s ease-in;
  box-shadow: none !important;
}
.werken-title{
  color: #f2696b;
  text-align: center;
}
.leven-title{
  color: #fed175;
  text-align: center;
}
.wonen-title{
  color: #5ac5be;
  text-align: center;
}
.wonen{
  padding-top: 2rem !important;
}
input.chk-btn {
  display: none;
}
input.chk-btn + label {
  border: 1px solid black;
  text-transform: uppercase;
  font-size: 15px;
  padding: 0;
  cursor: pointer;
  border-radius: 5px;
  color: black;
}
input.chk-btn:not(:checked) + label:hover {
  box-shadow: 2px 3px 1px;
}
input.chk-btn + label:active,
input.chk-btn:checked + label {
  background: darkgray;
  border: 1px solid darkgray !important;
}

/*    checkbox*/
@-moz-keyframes
dothabottomcheck {  0% {
  height: 0;
}
  100% {
    height: 15px;
  }
}
@-webkit-keyframes
dothabottomcheck {  0% {
  height: 0;
}
  100% {
    height: 15px;
  }
}
@keyframes
dothabottomcheck {  0% {
  height: 0;
}
  100% {
    height: 15px;
  }
}
@keyframes
dothatopcheck {  0% {
  height: 0;
}
  50% {
    height: 0;
  }
  100% {
    height: 35px;
  }
}
@-webkit-keyframes
dothatopcheck {  0% {
  height: 0;
}
  50% {
    height: 0;
  }
  100% {
    height: 60px;
  }
}
@-moz-keyframes
dothatopcheck {  0% {
  height: 0;
}
  50% {
    height: 0;
  }
  100% {
    height: 20px;
  }
}

input[type=checkbox] { display: none;}

.map-span{
  margin-left: 5px; color: black;
}

.check-box{
  height: 20px;
  width: 20px;
  top: 5px;
  margin-top: 20px;
  background-color: transparent;
  border: 1px solid black;
  border-radius: 5px;
  position: relative;
  display: inline-block;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-transition: border-color ease 0.2s;
  -o-transition: border-color ease 0.2s;
  -webkit-transition: border-color ease 0.2s;
  transition: border-color ease 0.2s;
  cursor: pointer;
}

.check-box::before,
.check-box::after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  height: 0;
  width: 3px;
  background-color: black;
  display: inline-block;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  border-radius: 5px;
  content: ' ';
  -webkit-transition: opacity ease .5;
  -moz-transition: opacity ease .5;
  transition: opacity ease .5;
}

.check-box::before {
  top: 17px;
  left: 10px;
  -moz-transform: rotate(-150deg);
  -ms-transform: rotate(-150deg);
  -o-transform: rotate(-150deg);
  -webkit-transform: rotate(-150deg);
  transform: rotate(-150deg);
}

.check-box::after {
  top: 3px;
  left: 2px;
  -moz-transform: rotate(-25deg);
  -ms-transform: rotate(-25deg);
  -o-transform: rotate(-25deg);
  -webkit-transform: rotate(-25deg);
  transform: rotate(-25deg);
}

input[type=checkbox]:checked + .check-box,
.check-box.checked { border-color: black; }

input[type=checkbox]:checked + .check-box::after,
.check-box.checked::after {
  height: 50px;
  -moz-animation: dothabottomcheck 0.2s ease 0s forwards;
  -o-animation: dothabottomcheck 0.2s ease 0s forwards;
  -webkit-animation: dothabottomcheck 0.2s ease 0s forwards;
  animation: dothabottomcheck 0.2s ease 0s forwards;
}

input[type=checkbox]:checked + .check-box::before,
.check-box.checked::before {
  height: 120px;
  -moz-animation: dothatopcheck 0.4s ease 0s forwards;
  -o-animation: dothatopcheck 0.4s ease 0s forwards;
  -webkit-animation: dothatopcheck 0.4s ease 0s forwards;
  animation: dothatopcheck 0.4s ease 0s forwards;
}


/*    Close icon infowindow*/
.info-window{
  text-align: center;
}
.info-window img{
  border-radius: 50%;
  margin: -7rem 0 1.5rem 1.5rem;
  position: absolute;
  top: -2.5rem;
  left: 25%;;
}
.link{
  text-decoration: underline;
}

.gm-ui-hover-effect{
  background: #fff !important;
  top: 0 !important;
  right: 0 !important;
  border: 1px solid #fff !important;
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
  opacity: 1;}
.gm-ui-hover-effect img{margin: -8px 6px !important;
  width: 16px !important;
  height: 16px !important;
  content:url("http://icons.iconarchive.com/icons/custom-icon-design/mono-general-1/24/delete-icon.png") !important;}
.gm-style .gm-style-iw {
  width: 300px !important;
  left: 0 !important;
  padding-left: 10px;
}

.circle{
  width: 14rem;
  height: 14rem;
  background-color: #fff;
  position: absolute;
  top: -10rem;
  left: 50%;
  border-radius: 50%;
  transform: translateX(-50%);
}

.gm-style-iw{
  overflow: visible !important;
}

.excerpt_map{
  width: 100%;
  margin: 0 auto;
  font-size: 15px;
  margin-bottom: -2rem;
}
