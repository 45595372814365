.m-hero {
	height:auto;
	background-size: cover;
	background-repeat: no-repeat;

	&__overlay {
		height: 100%;
		background-color: transparentize($color-gray, .55);
	}

	&__title {
		color: $color-white;
		font-size: 6rem;
		margin: -1rem 0 -2rem;
		@media (min-width: $breakpoint--md) {
			font-size: 8rem;
		}
		@media (min-width: $breakpoint--xl) {
			margin: 3rem 0 2rem
		}
		font-weight: $font-weight-bold;
		text-transform: uppercase;
		text-align: center;
	}

	&__intro {
		color: $color-white;
		padding: 0 10rem 5rem 10rem;
		//font-size: $font-size-medium;
		text-align: center;
		font-size: 2.2rem;
		@media (min-width: $breakpoint--md) {
			padding: 0 20rem 5rem 20rem;
			font-size: 2.4rem;
		}
		@media (min-width: $breakpoint--xl) {
			padding: 0 20rem 5rem 20rem;
			font-size: 2.8rem;
		}
	}

	@media (min-width:$breakpoint--xxl){
		&__steun-button{
			margin-top: 10rem;
		}
		&__title-section{
			margin-bottom: -10rem;
		}
	}

	&__down {
		margin-top: -2rem;
		img {
			max-width: 3rem;
			margin-top: 6rem;
		}
		@media (min-width: $breakpoint--xxl) {
			margin-top: 2rem;
		}
	}

	&__wonen{
		background-image:url('images/BOECHOUT Foto zijkant.jpg');
		height: calc(100vh - 120px);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: bottom;
	}

	&__werken{
		background-image:url('images/wijzersterk (1).JPG');
		height: calc(100vh);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}

	&__leven{
		background-image:url('images/CdM Ellen 1.jpg');
		height: calc(100vh);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}

	&__new{
		color: #fff;
		font-weight: 800;
	}

	&__link_wonen, &__link_wonen:focus, &__link_wonen:hover{
		background-color: #5ac5be;
		color: #fff;
		text-decoration: none;
	}

	&__link_werken, &__link_werken:focus, &__link_werken:hover{
		background-color: $color-red;
		color: #fff;
		text-decoration: none;
	}

	&__link_leven, &__link_leven:focus, &__link_leven:hover{
		background-color: #fed175;
		color: #fff;
		text-decoration: none;
	}
}
.m-hero-2{
	height: calc(100vh - 711px);
	background-repeat: no-repeat;
	background-size: cover;
}
.magazine-image{
	height: auto;
	width: 50%;
	margin: 0 auto;
	padding-bottom: 3rem;
}
