html {
    box-sizing: border-box;
    font-size: 54%;

    *, *:before, *:after {
        box-sizing: inherit;
    }

    @media (min-width: $breakpoint--md) {
        font-size: 62.5%;
    }
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $font-family-base;
    font-size: 16px;
    line-height: 1.6;
    color: $text-color;

    &.overflow {
        overflow: hidden;
    }

    ul{
        margin-bottom: 20px;
    }
}

::-moz-selection {
    text-shadow: none;
    color: get-gray-color('dark');
    background-color: get-gray-color('light');
}

a{
    text-decoration: underline;
}
a:hover {
    text-decoration: none;
    color: $color-gray;
    font-weight: 400;
}

input:focus {outline:0;}

.container {
    position: relative;

    max-width: 768px;
    width: 100% !important;
    height: 100%;
    margin: 0 auto;
    padding: 0 1.5rem;

    &--small {
        max-width: 500px;
    }

    &.no-padding {
        padding: 0;
    }

    @media (min-width: $breakpoint--md) {
        max-width: 890px;

        &--small {
            max-width: 700px;
        }
    }

    @media (min-width: $breakpoint--lg) {
        max-width: 1500px;

        &--small {
            max-width: 800px;
        }
    }
}

@media (min-width: 1100px) {
    #cmplz-document{
        min-width: 1000px;
    }
}

