$c: 'btn';

.#{$c} {
	font-size: 2rem;
	font-weight: $font-weight-bold;
	border-radius: 3px;
	text-transform: uppercase;

	&--white {
		background-color: $color-white;
		padding: 2rem 4rem;
		text-decoration: none;
	}

	&--black {
		background-color: $color-gray;
		color: $color-white !important;
		padding: 2rem 4rem;
		margin-top: 3rem;
		text-decoration: none;
	}

	&--small {
		font-size: 1.5rem;
		padding: 1rem 1.5rem;
		text-decoration: none;
	}

	&--extra-small {
		font-size: 1rem;
		padding: .5rem 1rem;
		text-decoration: none;
	}
}
.btn-projects{
	align-self: flex-end;
	margin-bottom: 4rem;
	margin-right: 4rem;
	float: left;
	text-decoration: none;
}
