.a-search {
	width: 100%;
	text-align: center;
	padding: 2rem 0;

	&__input {
		width: 50%;
		margin: 0 auto;
		display: inline-block;
		font-size: 3rem;
		border: none;
		border-bottom: 2px solid $color-gray;
		background-color: transparent;
	}

	@media (max-width:$breakpoint--md ) {
		&__faq_input{
			width: 80% !important;
		}
	}

	&__button {
		width: 3.5rem;
		height: auto;
		display: inline-block;
		margin-left: 2rem;
		margin-bottom: -1rem;
	}
}
#search-toggle {
	cursor: pointer;
}
#searchform {

	&.closed {
		// @media (min-width: $breakpoint--md) {
		display: none;
	// }
	}
}
