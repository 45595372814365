.m-booking {
	&__calendar {
		position: relative;
		margin: 0 auto;
	}
}
.abc-single-price{
	display: none;
}
.booking_info{
	margin: 0 auto;
	padding: 3rem;
}
.wpbc_structure_calendar{
	width: 330px !important;
}
.bk_calendar_frame{
	width: 330px !important;
}
